import { installApps } from './installServiceEditor';
import { getGUID } from '../utils/guid';
import { getPendingApps } from '../utils/pendingAppsService';
import { getAppType } from '../utils/appMarketUtils';
import {
  DocumentServicesObject,
  EditorClientSpecMapEntry,
} from '@wix/document-services-types';
import { WIX_CHAT } from '@wix/app-definition-ids';
import * as appMarketService from '../utils/appMarketService';
import {
  SilentInstallAppsCallbacks,
  SilentInstallAppsConfig,
  SilentInstallAppsOptions,
  AppInstallOption,
  AppInstallOrigin,
} from '@wix/editor-platform-host-integration-apis';
import { createDependenciesDriver } from './appDependenciesDriver';
import { IExperimentsApiAdapter } from '../utils/experimentsApiAdapter';

let callbacks: SilentInstallAppsCallbacks;
let config: SilentInstallAppsConfig;
let appNames: Record<string, string>;
let silentFlag: boolean;
let flowId: string;
let appsWithErrors: string[];

interface CommonAppOptions {
  sourceTemplateId?: string;
  origin: AppInstallOrigin;
  isSilent: boolean;
}

const initDefaultCallbacks: () => SilentInstallAppsCallbacks = () => ({
  afterAppInstall: (): Promise<void> => Promise.resolve(),
  beforeAppInstall: (): Promise<void> => Promise.resolve(),
  onInstallationError: (): Promise<void> => Promise.resolve(),
  onInstallationBegin: (): Promise<void> => Promise.resolve(),
  onInstallationEnd: (): Promise<void> => Promise.resolve(),
});

const setInitialState = () => {
  appNames = {};
  flowId = getGUID();
  appsWithErrors = [];
};

function getCommonAppOptions(
  appDefIds: string[],
  options: SilentInstallAppsOptions,
): Record<string, Partial<AppInstallOption>> {
  const { appsOptions } = options;
  return appDefIds.reduce((acc, appDefId) => {
    acc[appDefId] = {
      origin: config.platformOrigin,
      isSilent: true,
      ...appsOptions?.[appDefId],
    };
    return acc;
  }, {} as Record<string, CommonAppOptions>);
}

const isSilentInstallRunning = (): boolean => silentFlag;

const setIsSilentFlag = (val: boolean): void => {
  silentFlag = val;
};

const errorHandler =
  (appDefIds: string[], resolve: () => void, serialAppDefId?: string) =>
  async (err: Error, errName: string, appDefId: string = '') => {
    appsWithErrors.push(serialAppDefId || appDefId);
    if (!config.shouldInstallSerial) {
      setIsSilentFlag(false);
    }
    await callbacks.onInstallationError(
      err,
      errName,
      serialAppDefId || appDefId,
      appDefIds,
      appNames,
      flowId,
    );
    resolve();
  };

function install(
  documentServicesAPI: DocumentServicesObject,
  appDefIds: string[],
  options: SilentInstallAppsOptions,
  allAppDefIds: string[],
  serialInstallation: boolean,
): Promise<void> {
  return new Promise((resolve) => {
    installApps(
      documentServicesAPI,
      appDefIds,
      {
        appsOptions: getCommonAppOptions(appDefIds, options),
      },
      {
        singleAppCallback: singleAppInstalled(allAppDefIds),
        finishAllCallback: serialInstallation
          ? () => resolve()
          : finishAllCallback(allAppDefIds, resolve),
        onError: errorHandler(
          allAppDefIds,
          resolve,
          serialInstallation ? appDefIds[0] : undefined,
        ),
      },
    );
  });
}

async function installAppDependencies(
  documentServicesAPI: DocumentServicesObject,
  appDefId: string,
  appDefIds: string[],
) {
  const DependenciesDriver = await createDependenciesDriver(
    documentServicesAPI,
    appDefId,
  );

  const { appsToInstall, dependencyOptions } =
    DependenciesDriver.getAppsToInstall();

  const dependenciesToInstall = appsToInstall.filter(
    (dependencyAppDefId) =>
      dependencyAppDefId !== appDefId &&
      !appDefIds.includes(dependencyAppDefId),
  );

  for (const dependencyAppDefId of dependenciesToInstall) {
    await install(
      documentServicesAPI,
      [dependencyAppDefId],
      { appsOptions: dependencyOptions },
      appDefIds,
      true,
    );
  }
}

async function installAppsSerially(
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  appDefIds: string[],
  options: SilentInstallAppsOptions,
): Promise<void> {
  for (const appDefId of appDefIds) {
    await callbacks.beforeAppInstall(appDefId, appDefIds, appNames, flowId);

    if (experimentsAPI.enabled('se_installAppDependenciesInSilent')) {
      await installAppDependencies(documentServicesAPI, appDefId, appDefIds);
    }
    await install(documentServicesAPI, [appDefId], options, appDefIds, true);
  }
  await finishAllCallback(appDefIds, () => {})();
}

const finishAllCallback =
  (appDefIds: string[], resolve: () => void) => async () => {
    setIsSilentFlag(false);
    await callbacks.onInstallationEnd(
      appsWithErrors,
      appDefIds,
      appNames,
      flowId,
    );
    resolve();
  };

const singleAppInstalled =
  (appDefIdsToSilentInstall: string[]) => async (installedAppDef: string) => {
    await callbacks.afterAppInstall(
      installedAppDef,
      appDefIdsToSilentInstall,
      appNames,
      flowId,
    );
  };

const canInstallAppSilently = (appData: EditorClientSpecMapEntry) => {
  if (config.shouldInstallWidgets) {
    return true;
  }
  // Temporary check for first phase of pending to silent.
  // Chat is a widget app that can be installed with the silent flow.
  // In the next phase, all apps would be able to install through silent, so the filter would be removed.
  const isChat = appData.appDefinitionId === WIX_CHAT;
  const type = getAppType(appData);
  return isChat || type !== 'tpaWidget';
};

async function silentInstall(
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  appsToInstallSilently: string[] = [],
  options: SilentInstallAppsOptions,
  serviceConfig: SilentInstallAppsConfig,
  serviceCallbacks: Partial<SilentInstallAppsCallbacks>,
): Promise<void> {
  if (isSilentInstallRunning()) {
    throw new Error('Silent install already in progress');
  }
  config = serviceConfig;
  callbacks = { ...initDefaultCallbacks(), ...serviceCallbacks };
  const shouldInstallPending = config.shouldInstallPending ?? true;
  const pendingSectionApps = shouldInstallPending
    ? getPendingApps(documentServicesAPI, canInstallAppSilently)
    : [];
  const pendingAppDefs = pendingSectionApps.map((app) => app.appDefinitionId);
  const appsToInstall = [...appsToInstallSilently, ...pendingAppDefs];
  if (appsToInstall.length) {
    setIsSilentFlag(true);
  } else {
    setIsSilentFlag(false);
    return;
  }
  setInitialState();

  const appDefIdsToSilentInstall = appsToInstall.filter(
    (appDefId) => !documentServicesAPI.platform.isAppActive(appDefId),
  );

  if (!appDefIdsToSilentInstall.length) {
    setIsSilentFlag(false);
    return;
  }
  try {
    appNames = await appMarketService.fetchAppsNames(appDefIdsToSilentInstall);

    await callbacks.onInstallationBegin(
      appDefIdsToSilentInstall,
      appNames,
      flowId,
    );

    if (config.shouldInstallSerial) {
      await installAppsSerially(
        documentServicesAPI,
        experimentsAPI,
        appDefIdsToSilentInstall,
        options,
      );
    } else {
      await install(
        documentServicesAPI,
        appDefIdsToSilentInstall,
        options,
        appDefIdsToSilentInstall,
        false,
      );
    }
  } catch (e) {
    setIsSilentFlag(false);
    throw e;
  }
}

export { silentInstall, isSilentInstallRunning };
