import {
  DocumentServicesObject,
  PageRef,
  RouterRef,
} from '@wix/document-services-types';
import {
  getReplacer,
  getReplacersByPageRole,
  Replacers,
} from '../utils/pageReplaceService/replacers';
import {
  connectPage,
  getPageRoleByPageId,
  INFIX,
} from '../utils/pageReplaceService/pages';
import {
  AppManifest,
  PageVariant,
  VariantsData,
  PanelResolveType,
  Rule,
  PageRule,
} from '@wix/editor-platform-sdk-types';
import { invert } from 'lodash';
import {
  getVariantsByPageRole,
  getVariant,
} from '../utils/pageReplaceService/variants';
import { PlatformContext } from '../../types/platformApi';
import { reportBi } from '../../essentials';
import { t } from '../../essentials/i18n';

export const REPLACEMENT_PAGE_SUCCEEDED_EVENT_ID = 111;
export const PAGES_REPLACE_WITH_CUSTOM_PAGE_CHOICE_EVENT_ID = 110;
export const PAGES_REPLACE_TPA_MESSAGE_LOADED_EVENT_ID = 113;
export const DEFAULT_REPLACER_HELP_URL =
  'https://dev.wix.com/docs/develop-websites/articles/wix-apps/build-a-custom-wix-business-app-page';

export function setReplacerPage(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    replacerPageRef,
    setAsActive,
  }: {
    pageRef: PageRef;
    replacerPageRef: PageRef;
    setAsActive: boolean;
  },
): void {
  if (!pageRef?.id || !replacerPageRef?.id) {
    return;
  }
  const routerRef = documentServices.routers.getRouterRef.byPage(pageRef);
  if (!routerRef) {
    throw new Error('target page is not connected to router');
  }

  let routerData = documentServices.routers.getRouterDataForPageIfExist(
    replacerPageRef.id,
  );

  if (!routerData) {
    connectPage(
      documentServices,
      routerRef,
      pageRef,
      replacerPageRef,
      undefined,
      INFIX.REPLACER,
    );
  } else if (!getPageRoleByPageId(routerData.pages, pageRef.id)) {
    throw new Error('replacing page is already in use within another router');
  }

  documentServices.waitForChangesApplied(() => {
    routerData = documentServices.routers.get.byRef(routerRef);
    // config should be string, but routers.byRef returns routerConfig as JSON
    const routerConfig = JSON.stringify(routerData.config);
    const pageRole = getPageRoleByPageId(routerData.pages, pageRef.id);

    if (!pageRole) {
      throw new Error('pageRole is not found');
    }

    const replacerPageRole = getPageRoleByPageId(
      routerData.pages,
      replacerPageRef.id,
    );

    if (!replacerPageRole) {
      throw new Error('replacerPageRole is not found');
    }

    let pageRoleReplacers: Replacers | undefined;

    const isOriginalPageRoleReplacer = pageRole === replacerPageRole;

    if (setAsActive) {
      if (isOriginalPageRoleReplacer) {
        const replacer = getReplacersByPageRole(routerConfig, pageRole).find(
          (r) => r.active,
        );
        pageRoleReplacers = replacer && [
          { role: replacer.role, active: false },
        ];
      } else {
        pageRoleReplacers = [
          {
            role: replacerPageRole,
            active: setAsActive,
          },
        ];
      }
    } else if (!isOriginalPageRoleReplacer) {
      pageRoleReplacers = [{ role: replacerPageRole, active: setAsActive }];
    }

    if (pageRoleReplacers) {
      updateRouterConfig(
        documentServices,
        routerConfig,
        routerRef,
        pageRole,
        pageRoleReplacers,
      );
    }
  });
}

export async function openPageVariantsPanel(
  documentServices: DocumentServicesObject,
  context: PlatformContext,
  {
    pageTitle,
    variants,
    pageManagingAppDefId,
    pageRef,
  }: {
    pageTitle: string;
    variants: PageVariant[];
    pageManagingAppDefId: string;
    pageRef: PageRef;
  },
) {
  const panelName = 'platformPanels.PageVariantsPanel';
  const variantsHelpUrl = getVariantsHelpUrl(
    documentServices,
    pageManagingAppDefId,
    pageRef,
  );
  return context.platformApiMethods.editor.openPanel(
    {},
    panelName,
    {
      origin: 'pages_panel',
      name: panelName,
      title: `${t('PLATFORM_flow_customization_variant_preset_modal_title', {
        page_name: pageTitle,
      })}`,
      variants,
      variantsHelpUrl,
      pageManagingAppDefId,
    },
    true,
  );
}

export async function addVariantToPage(
  documentServices: DocumentServicesObject,
  {
    pageRef,
    variantPageRef,
    rule,
    variantId,
  }: {
    pageRef: PageRef;
    variantPageRef: PageRef;
    rule: Rule;
    variantId: string;
  },
): Promise<void> {
  let routerRef: RouterRef;
  routerRef = documentServices.routers.getRouterRef.byPage(pageRef);
  if (!routerRef && !isRouterExistForPage(documentServices, pageRef)) {
    routerRef = await createRouter(documentServices, pageRef);
    await connectPageToRouter(documentServices, routerRef, pageRef);
    if (!routerRef) {
      throw new Error('could not create router');
    }
  }

  const variantPageRole = connectPage(
    documentServices,
    routerRef,
    pageRef,
    variantPageRef,
    variantId,
    INFIX.VARIANT,
  );
  const routerData = documentServices.routers.get.byRef(routerRef);
  const pageRole = getPageRoleByPageId(routerData.pages, pageRef.id);
  if (!pageRole) {
    throw new Error('connectVariant failed - pageRole is not found');
  }

  documentServices.waitForChangesApplied(() => {
    const routerConfig = JSON.stringify(routerData.config);
    updateRouterConfig(
      documentServices,
      routerConfig,
      routerRef,
      pageRole,
      undefined,
      rule,
      variantPageRole,
    );
  }, false);
}

function getUpdatedRoleVariations(
  config: any,
  ruleId: string,
  pageRole: string,
) {
  const roleVariations = config.roleVariations ?? {};
  if (roleVariations[pageRole]) {
    roleVariations[pageRole].push(ruleId);
  } else {
    roleVariations[pageRole] = [ruleId];
  }
  return roleVariations;
}

function addRule(config: any, ruleId: string, newRule: Rule, pageRole: string) {
  const existingRules = config.rules ?? {};
  const addRule: PageRule = { ...newRule, pageRole };
  return { ...existingRules, [ruleId]: addRule };
}

function generateUUID() {
  return new Array(2)
    .fill(0)
    .map(() => Math.floor(Math.random() * Number.MAX_SAFE_INTEGER).toString(4))
    .join('-');
}

function updateRouterConfig(
  documentServices: DocumentServicesObject,
  routerConfig: string,
  routerRef: RouterRef,
  pageRole: string,
  pageRoleReplacers?: Replacers,
  rule?: Rule,
  variantPageRole?: string,
) {
  let rules, roleVariations;
  const configObj = JSON.parse(routerConfig);
  const replacers = configObj.replacers ?? {};
  const ruleId = generateUUID();
  if (rule && variantPageRole) {
    rules = addRule(configObj, ruleId, rule, variantPageRole);
    roleVariations = getUpdatedRoleVariations(configObj, ruleId, pageRole);
  }

  const config = JSON.stringify({
    ...configObj,
    ...(pageRoleReplacers && {
      replacers: {
        ...replacers,
        [pageRole]: pageRoleReplacers,
      },
    }),
    ...(rule && { rules }),
    ...(rule && { roleVariations }),
  });

  documentServices.routers.update(routerRef, { config });
}

export function getPageInfo(
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
) {
  if (!pageRef.id) {
    throw new Error('Could not get page info because of missing page id');
  }
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );
  if (routerData) {
    const appData = documentServices.platform.getAppDataByAppDefId(
      routerData.appDefinitionId,
    );
    if (appData?.appDefinitionName) {
      const pageData = documentServices.pages.data.get(pageRef.id);
      return {
        isCustomPage: !pageData?.managingAppDefId,
        hostAppName: appData.appDefinitionName,
        hostAppDefinitionId: appData.appDefinitionId!,
        pageTitle: pageData?.title,
        managingAppDefId: pageData?.managingAppDefId,
      };
    } else {
      throw new Error(
        'routerData connected to an app without app data or the app is not installed',
      );
    }
  } else {
    throw new Error(`pageId: '${pageRef.id}' is not connected to any router`);
  }
}

export const isRouterExistForPage = (
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
) => {
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );
  return !!routerData;
};

export const createRouter = (
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
) => {
  const pageData = documentServices.pages.data.get(pageRef.id);
  const prefix = pageData.pageUriSEO;
  const appDefinitionId =
    pageData?.appDefinitionId || pageData?.managingAppDefId;
  if (!appDefinitionId) {
    throw new Error('appDefinitionId is missing in createRouter');
  }
  documentServices.pages.data.update(pageRef.id, {
    pageUriSEO: `${pageData.pageUriSEO}-static`,
  });
  return new Promise<RouterRef>((resolve) => {
    documentServices.waitForChangesApplied(() => {
      const routerRef = documentServices.routers.add({
        appDefinitionId,
        config: '{}',
        prefix,
      });
      resolve(routerRef);
    });
  });
};

export const connectPageToRouter = (
  documentServices: DocumentServicesObject,
  routerRef: RouterRef,
  pageRef: PageRef,
) => {
  const { appDefinitionId, managingAppDefId, tpaPageId } =
    documentServices.pages.data.get(pageRef.id);
  const appDefId: string = appDefinitionId ?? managingAppDefId!;
  const pageReplaceOptions = getPageReplaceOptions(
    documentServices,
    appDefId,
    pageRef,
  );
  if (!tpaPageId) {
    throw new Error('tpaPageId is missing in connectPageToRouter');
  }
  documentServices.routers.pages.connect(
    routerRef,
    pageRef,
    [tpaPageId],
    pageReplaceOptions?.slug ? { innerRoute: pageReplaceOptions.slug } : {},
  );
  return new Promise<boolean>((resolve) => {
    documentServices.waitForChangesApplied(() => resolve(true));
  });
};

export function getPageVariations(
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): { replacers: string[]; variants: string[] } | undefined {
  if (!pageRef.id) {
    throw new Error('pageRef must have an id');
  }
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );

  if (routerData) {
    const role = Object.keys(routerData.pages).find(
      (pageRole) => routerData.pages[pageRole] === pageRef.id,
    )!;
    const replacers = getReplacersByPageRole(routerData.config, role);
    const replacerIds = replacers.map(
      (replacer) => routerData.pages[replacer.role],
    );
    const variants = getVariantsByPageRole(routerData.config, role);
    const variantIds = variants.map((variant) => routerData.pages[variant]);
    return { replacers: replacerIds, variants: variantIds };
  }

  return undefined;
}

export const getPageReplaceOptions = (
  documentServices: DocumentServicesObject,
  appDefinitionId: string,
  pageRef: PageRef,
) => {
  const appManifest = documentServices.platform.getAppManifest(
    appDefinitionId,
  ) as AppManifest;
  const pageData = documentServices.pages.data.get(pageRef.id);
  if (pageData?.tpaPageId) {
    const pageReplaceOptions =
      appManifest?.pages?.applicationSettings?.default?.pageReplace?.[
        pageData.tpaPageId
      ];

    return pageReplaceOptions;
  }

  return undefined;
};

export function isReplaceable(
  documentServices: DocumentServicesObject,
  appDefinitionId: string,
  pageRef: PageRef,
): boolean {
  const pageReplaceOptions = getPageReplaceOptions(
    documentServices,
    appDefinitionId,
    pageRef,
  );
  return !!pageReplaceOptions?.replaceable;
}

export function getPageAvailableVariants(
  documentServices: DocumentServicesObject,
  appDefinitionId: string,
  pageRef: PageRef,
): VariantsData['variants'] | undefined {
  const pageReplaceOptions = getPageReplaceOptions(
    documentServices,
    appDefinitionId,
    pageRef,
  );
  const pageVariants = pageReplaceOptions?.variantsData?.variants;

  const routerRef = documentServices.routers.getRouterRef.byPage(pageRef);
  if (!routerRef) {
    return pageVariants;
  }
  const routerData = documentServices.routers.get.byRef(routerRef);
  const notChosenVariants = pageVariants?.filter((variant: PageVariant) => {
    const variantId = variant?.id;
    const isVariantChosen = Object.keys(routerData.pages).some((pageRole) =>
      pageRole.includes(variantId),
    );
    return !isVariantChosen;
  });
  return notChosenVariants;
}

export function isReplacer(
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): boolean {
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );
  if (routerData) {
    const replacer = getReplacer(routerData.config, routerData.pages, pageRef);
    return !!replacer;
  }
  return false;
}

export function isVariant(
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): boolean {
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );
  if (routerData) {
    const variant = getVariant(routerData.config, routerData.pages, pageRef);
    return variant;
  }
  return false;
}

export function isReplacerActive(
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): boolean {
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );
  if (routerData) {
    const replacer = getReplacer(routerData.config, routerData.pages, pageRef);
    return !!replacer?.active;
  }
  return false;
}

export function getOriginalPageRef(
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): PageRef | undefined {
  const routerData = documentServices.routers.getRouterDataForPageIfExist(
    pageRef.id,
  );
  if (routerData) {
    const pages = invert(routerData.pages);
    const pageRole = pages[pageRef.id];
    const originalPageRole = /(.+)_[replacer|variant]+/.exec(pageRole)?.[1];
    const originalPageId =
      originalPageRole && routerData.pages?.[originalPageRole];
    if (originalPageId) {
      return { ...pageRef, id: originalPageId, pageId: originalPageId };
    }
  }

  return undefined;
}

export function getReplacerHelpUrl(
  documentServices: DocumentServicesObject,
  appDefinitionId: string,
  pageRef: PageRef,
): string {
  const pageReplaceOptions = getPageReplaceOptions(
    documentServices,
    appDefinitionId,
    pageRef,
  );

  return pageReplaceOptions?.replacerHelpUrl ?? DEFAULT_REPLACER_HELP_URL;
}

export function getVariantsHelpUrl(
  documentServices: DocumentServicesObject,
  appDefinitionId: string,
  pageRef: PageRef,
): string | undefined {
  const pageReplaceOptions = getPageReplaceOptions(
    documentServices,
    appDefinitionId,
    pageRef,
  );

  return pageReplaceOptions?.variantsData?.variantsHelpUrl;
}

export async function createBlankReplacerPage(
  context: PlatformContext,
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): Promise<void> {
  if (!pageRef.id) {
    throw new Error(
      'in order to create a replacer page, you must pass pageRef',
    );
  }
  if (!isRouterExistForPage(documentServices, pageRef)) {
    const routerRef = await createRouter(documentServices, pageRef);
    await connectPageToRouter(documentServices, routerRef, pageRef);
  }
  const pageDataRequestDetails = getPageInfo(documentServices, pageRef);
  if (
    !isReplaceable(
      documentServices,
      pageDataRequestDetails?.hostAppDefinitionId,
      pageRef,
    )
  ) {
    throw new Error('app does not support replacement of this page');
  }

  const replacerHelpUrl = getReplacerHelpUrl(
    documentServices,
    pageDataRequestDetails?.hostAppDefinitionId,
    pageRef,
  );

  const userAction =
    await context.platformApiMethods.editor.openConfirmationPanel({}, 'token', {
      helpId: '0eac6fdf-f5fb-467f-af0d-fea1b62c652b',
      headerText: t('PLATFORM_flow_customization_create_page_modal_title'),
      shouldShowIllustration: true,
      symbol: 'replaceCustomPage',
      descriptionText: [
        {
          tag: 'p',
          content: t('PLATFORM_flow_customization_create_page_modal_text', {
            app_name: pageDataRequestDetails?.hostAppName,
            page_name: pageDataRequestDetails?.pageTitle,
          }),
        },
      ],
      secondaryActionText: t(
        'PLATFORM_flow_customization_create_page_modal_cancel_button',
      ),
      mainActionText: t(
        'PLATFORM_flow_customization_create_page_modal_create_button',
      ),
      footnote: {
        footnoteText: t('PLATFORM_flow_customization_create_page_modal_note'),
        urlText: t(
          'PLATFORM_flow_customization_create_page_modal_note_learn_more_link',
        ),
        urlLink: replacerHelpUrl,
      },
    });

  if (userAction === PanelResolveType.MAIN_ACTION) {
    reportBi(PAGES_REPLACE_WITH_CUSTOM_PAGE_CHOICE_EVENT_ID, {
      target: 'create',
    });
    const blankReplacerPage =
      await context.platformApiMethods.document.pages.add({}, 'token', {
        title: t(
          'PLATFORM_flow_customization_pages_panel_custom_page_default_name',
          { page_name: pageDataRequestDetails?.pageTitle },
        ),
        shouldAddMenuItem: false,
        shouldNavigateToPage: false,
      });
    setReplacerPage(documentServices, {
      pageRef,
      replacerPageRef: blankReplacerPage,
      setAsActive: true,
    });
    await context.platformApiMethods.document.pages.navigateTo({}, 'token', {
      pageRef: blankReplacerPage,
    });
    context.platformApiMethods.editor.openPagesPanel({}, 'token', {
      pageRef: blankReplacerPage,
      renameEnabled: true,
    });
    reportBi(REPLACEMENT_PAGE_SUCCEEDED_EVENT_ID, {
      original_page: pageRef.id,
      replacement_page: blankReplacerPage.id,
      app_id: pageDataRequestDetails?.managingAppDefId,
      host_app_id: pageDataRequestDetails.hostAppDefinitionId,
    });
  } else {
    reportBi(PAGES_REPLACE_WITH_CUSTOM_PAGE_CHOICE_EVENT_ID, {
      target: 'cancel',
    });
  }
}

export function openDeleteReplacerPageModal(
  context: PlatformContext,
  documentServices: DocumentServicesObject,
  pageRef: PageRef,
): Promise<PanelResolveType> {
  const pageDataRequestDetails = getPageInfo(documentServices, pageRef);
  const originalPage = getOriginalPageRef(documentServices, pageRef);
  const originalPageData =
    originalPage && documentServices.pages.data.get(originalPage.id);

  reportBi(PAGES_REPLACE_TPA_MESSAGE_LOADED_EVENT_ID, {
    app_id: pageDataRequestDetails.hostAppDefinitionId,
    description: 'page_replace_custom_delete',
    type: 'error_message',
  });

  return context.platformApiMethods.editor.openErrorPanel({}, 'token', {
    helpId: 'c504d2bd-c9ad-4d7a-bf51-9e79a5856966',
    headerText: t('PLATFORM_flow_customization_delete_page_modal_title'),
    shouldShowIllustration: true,
    symbol: 'replaceDeletePage',
    topDescriptionText: [
      {
        tag: 'p',
        content: t('PLATFORM_flow_customization_delete_page_modal_text', {
          page_name: originalPageData?.title,
        }),
      },
    ],
    secondaryActionText: t(
      'PLATFORM_flow_customization_delete_page_modal_cancel_button',
    ),
    mainActionText: t(
      'PLATFORM_flow_customization_delete_page_modal_delete_button',
    ),
  });
}
