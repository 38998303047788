import { HttpClient } from '@wix/http-client';
import type {
  AppDataComponent,
  DocumentServicesObject,
} from '@wix/document-services-types';
import { CompRef } from '@wix/document-services-types';
import type { PluginInstallationParams } from '@wix/editor-platform-host-integration-apis';
import type { PluginPlacement } from '@wix/ambassador-devcenter-appsruntimereader-v1-app-runtime-data/types';
import {
  ComponentType,
  WidgetPluginComponentData,
} from '@wix/ambassador-app-service-webapp/types';
import { SlotData, WidgetPointer } from '../../types/widgetPlugins';
import { IExperimentsApiAdapter } from '../utils/experimentsApiAdapter';
import { findIndex } from 'lodash';

interface SlotsResponse {
  slots: SlotData[];
}

const getSlotComponentRef = (
  documentServicesAPI: DocumentServicesObject,
  slot: SlotData,
): CompRef | null => {
  const VIRTUAL_SLOT_ID_DIVIDER = '_vs_';
  const SLOT_ID_DIVIDER = '_r_';
  const isBlocksSlot = slot?.scope?.componentIds?.length;

  if (isBlocksSlot) {
    const compRefId = [...slot!.scope!.componentIds!, slot.componentId].join(
      SLOT_ID_DIVIDER,
    );

    return documentServicesAPI.components.get.byId(compRefId);
  } else {
    const widgetCompRef = documentServicesAPI.components.get.byId(
      slot.componentId,
    );

    if (!widgetCompRef) {
      return null;
    }

    return {
      id: `${widgetCompRef.id}${VIRTUAL_SLOT_ID_DIVIDER}${slot.slotId}`,
      type: widgetCompRef.type,
    };
  }
};

const getAvailableSlots = async (
  documentServicesAPI: DocumentServicesObject,
  pluginPlacements: PluginPlacement[] = [],
): Promise<SlotData[]> => {
  const META_SITE_APPLICATION_ID = '-666';
  const getAppToken = () =>
    documentServicesAPI.platform.getAppDataByApplicationId(
      META_SITE_APPLICATION_ID,
    )?.instance;

  const httpClient = new HttpClient({
    baseURL: '/_serverless/editor-platform-widget-plugins/',
    getAppToken,
  });

  if (!pluginPlacements?.length) {
    return [];
  }

  const queryParams = new URLSearchParams();
  queryParams.append('placements', JSON.stringify(pluginPlacements));

  const res = await httpClient.get<SlotsResponse>(
    `/slots?${queryParams.toString()}`,
  );

  return res.data.slots;
};

function sortSlotsByPluginPlacements(
  slots: SlotData[],
  placements: PluginPlacement[],
) {
  const sortedSlots = [...slots].sort(function (a, b) {
    return (
      findIndex(placements, { widgetId: a.widgetId, slotId: a.slotId }) -
      findIndex(placements, { widgetId: b.widgetId, slotId: b.slotId })
    );
  });
  return sortedSlots;
}

const getPluginInstallationParams = async (
  documentServicesAPI: DocumentServicesObject,
  experimentsAPI: IExperimentsApiAdapter,
  appDefinitionId: string,
  components: AppDataComponent[],
): Promise<PluginInstallationParams | null> => {
  const isPluginCompType = (comp: AppDataComponent) =>
    comp.type === ComponentType.WIDGET_PLUGIN;

  // WidgetPluginComponentData has an explicit type as AppDataComponent['data'] doesn't provide a correct type for all possible component types
  // @ts-expect-error
  const widgetPlugins: WidgetPluginComponentData[] =
    components?.filter(isPluginCompType).map((w) => w.data) ?? [];

  if (widgetPlugins?.length === 0) {
    return null;
  }

  for (const plugin of widgetPlugins) {
    if (experimentsAPI.enabled('se_wpAppMarketAutoInstallParams')) {
      // Remove comment when WidgetPluginComponentData is updated in @wix/ambassador-app-service-webapp
      // @ts-expect-error
      if (!plugin.installation?.base?.autoAdd) {
        continue;
      }
    }

    const availableSlots = await getAvailableSlots(
      documentServicesAPI,
      plugin.placements,
    );

    if (!availableSlots.length) {
      continue;
    }

    const [slot] = sortSlotsByPluginPlacements(
      availableSlots,
      plugin.placements || [],
    );
    const slotComponentRef = getSlotComponentRef(documentServicesAPI, slot);

    if (!slotComponentRef) {
      return null;
    }

    const widgetRef: WidgetPointer = {
      appDefinitionId,
      widgetId: plugin.referenceComponentId!,
    };

    return {
      slotComponentRef,
      widgetRef,
      pageId: slot.pageId,
    };
  }

  return null;
};

export { getPluginInstallationParams };
