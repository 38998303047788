import {
  CompRef,
  DocumentServicesObject,
  PageRef,
} from '@wix/document-services-types';
import { chainPromisesAsync } from '../utils';
import {
  MainPresets,
  UnifiedComponentsCollection,
  UnifiedPage,
  UnifiedWidget,
  WidgetAsContent,
  AppData,
} from '../../../../types/unifiedComponents';
import {
  createAddUnifiedComponentsError,
  AddUnifiedComponentsErrorCode as ErrorType,
} from '../errors';
import { PlatformContext } from '../../../../types/platformApi';
import { PAGE_REGIONS } from '../constants';
import { addBlocksClosedWidget } from './addBlocksWidget';

// TODO: implement this function to handle `essential` and `maxInstances` properties
// Do we need this function at this phase?
function _getComponentNumberOfInstances(
  _documentServices: DocumentServicesObject,
  _appDefinitionId: string,
  _compId: string,
) {
  return;
}

export function getAddUnifiedComponentsMethods(
  context: PlatformContext,
  documentServices: DocumentServicesObject,
  appData: AppData,
) {
  // TODO: Force new section to be the last one (currently 'randomly' added in page)
  // TODO: Use real `addBlankSection` when it exposed in santa-editor
  // TODO: Provide real appData (if required through params)
  function getNewSectionInPage() {
    return new Promise<CompRef>((resolve, reject) =>
      context.platformApiMethods.editor.components
        .addBlankSection({})
        .then(resolve)
        .catch((error: Error) =>
          reject(
            createAddUnifiedComponentsError(
              ErrorType.creatingNewSectionFailed,
              'Could not create new section',
            ).withParentError(error),
          ),
        ),
    );
  }

  // TODO: Handle Itay note - do we want to allow `addToSiteMenu` at this phase?
  function createBlankPage(pageDefinition: UnifiedPage) {
    return new Promise<PageRef>((resolve, reject) => {
      const pageUriSEO = pageDefinition.installation?.page?.slug
        ?.split('/')
        .pop();
      if (!pageUriSEO) {
        // TODO: Should we create logic that define a default?
        reject(
          createAddUnifiedComponentsError(
            ErrorType.pageHaveNoSlugDefinition,
            `The page ${pageDefinition.base?.name} have no slug definition`,
          ),
        );
      }
      const pageStructureOverrides = {
        data: {
          pageUriSEO,
        },
      };

      // validate if `addToSiteMenu` option truly able to use - not good API
      try {
        const pageRef = documentServices.pages.add(
          pageDefinition.base?.name,
          pageStructureOverrides,
          // @ts-expect-error
          pageDefinition.installation.page.addToSiteMenu,
        );
        resolve(pageRef);
      } catch (error) {
        reject(error);
      }
    });
  }

  // TODO: Align validation before adding the page product-ux-wise
  function addPage(
    pageDefinition: UnifiedPage,
    widgets: UnifiedComponentsCollection['widgets'],
  ): Promise<PageRef | undefined> {
    return new Promise((resolve, reject) => {
      if (
        !pageDefinition.installation?.base?.autoAdd &&
        !pageDefinition.installation?.base?.essential
      ) {
        resolve(undefined);
      }

      // TODO: Do we want to throw error or to generate alternative slug?
      createBlankPage(pageDefinition)
        .then((thisPageRef) =>
          navigateToPageAndAddContent(pageDefinition, widgets, thisPageRef),
        )
        .then(resolve)
        .catch((error: Error) =>
          reject(
            createAddUnifiedComponentsError(
              ErrorType.creatingNewPageFailed,
              `Failed to create ${pageDefinition.base?.id} page`,
            )
              .withUserComponentId(
                pageDefinition.base?.id as string,
                'unified-page',
              )
              .withParentError(error as Error),
          ),
        );
    });
  }

  // COMPLETED
  function navigateToPageAndAddContent(
    pageDefinition: UnifiedPage,
    widgets: UnifiedComponentsCollection['widgets'],
    pageRef: PageRef,
  ): Promise<PageRef> {
    const addWidgetAsContent = (widget: WidgetAsContent) =>
      new Promise((reoslve, reject) => {
        const { widgetGuid, preset } = widget;
        const widgetDefinition = widgets[widgetGuid as string];
        if (!widgetDefinition) {
          reject(
            createAddUnifiedComponentsError(
              ErrorType.widgetGuidNotFound,
              `Could not find widget: ${widgetGuid} to install over page: ${pageDefinition.base?.name}`,
            ).withUserComponentId(widgetGuid as string, 'unified-widget'),
          );
        }

        addWidgetWithinNewSection(pageRef, widgetDefinition, preset)
          .then(reoslve)
          .catch(reject);
      });

    return new Promise((resolve, reject) => {
      const addWidgetsAfterNavigation = () => {
        chainPromisesAsync(
          pageDefinition.content?.widgets ?? [],
          addWidgetAsContent,
        )
          .then(() => resolve(pageRef))
          .catch(reject);
      };

      const onNavigationError = () =>
        reject(
          createAddUnifiedComponentsError(
            ErrorType.navigationToNewPageFailed,
            `Failed to navigate created page ${pageDefinition.base?.id}`,
          )
            .withPageRef(pageRef, true)
            .withUserComponentId(
              pageDefinition.base?.id as string,
              'unified-page',
            ),
        );

      documentServices.pages.navigateTo(
        pageRef.pageId,
        addWidgetsAfterNavigation,
        onNavigationError,
      );
    });
  }

  // COMPLETED
  function addWidgetWithinNewSection(
    pageRef: PageRef,
    widgetDefinition: UnifiedWidget,
    preset?: MainPresets,
  ): Promise<PageRef> {
    return new Promise((resolve, reject) =>
      getNewSectionInPage()
        .then((newSectionRef) =>
          addWidgetInSection(widgetDefinition, newSectionRef, preset),
        )
        .then(() => {
          resolve(pageRef);
        })
        .catch((error) => {
          reject(
            createAddUnifiedComponentsError(
              ErrorType.addingWidgetIntoSectionFailed,
              'failed to add widget within new section',
            )
              .withPageRef(pageRef, true)
              .withUserComponentId(
                widgetDefinition.base.id as string,
                'unified-widget',
              )
              .withParentError(error),
          );
        }),
    );
  }

  // TODO: 1. Do not add widget when it installed as 'maxInstances' times - trigger installation failure instead.
  // TODO: 2. Support adding non-Blocks widget to stage
  // TODO: 3. Pass proper options through ds.appStudioWidgets.addWidget method
  // TODO: 4. Why adding blocks widget isn't centered
  function addWidgetInSection(
    widgetDefinition: UnifiedWidget,
    containerRef: CompRef,
    preset?: MainPresets,
  ): Promise<CompRef> {
    return new Promise((resolve, reject) => {
      preset = preset ?? widgetDefinition.installation.widget?.defaultPreset;
      const { widgetId } = widgetDefinition;
      if (widgetDefinition.type === 'STUDIO_WIDGET') {
        const { mobilePresetId, desktopPresetId: presetId } = preset ?? {};
        addBlocksClosedWidget(context, appData, {
          widgetId,
          containerRef,
          presetId,
          mobilePresetId,
        })
          .then(resolve)
          .catch(reject);
      } else {
        reject(
          createAddUnifiedComponentsError(
            ErrorType.widgetTypeNotSupported,
            `There is no implementation to support adding ${widgetDefinition.type} widget`,
          ).withUserComponentId(widgetId, 'unified-widget'),
        );
      }
    });
  }

  // TODO: 1. Force auto add when 'essential' and widget hasn't installed yet ???
  // TODO: 2. Fix compRef of Home page
  function addWidget(widgetDefinition: UnifiedWidget, preset?: MainPresets) {
    return new Promise<void>((resolve, reject) => {
      if (!widgetDefinition.installation.base?.autoAdd) {
        resolve();
      }

      const { region, defaultPreset } =
        widgetDefinition.installation.widget ?? {};
      if (region === PAGE_REGIONS.BODY) {
        getNewSectionInPage()
          .then((sectionRef: CompRef) => {
            addWidgetInSection(
              widgetDefinition,
              sectionRef,
              preset ?? defaultPreset,
            );
          })
          .then(resolve)
          .catch((error: Error) =>
            reject(
              createAddUnifiedComponentsError(
                ErrorType.addingWidgetIntoSectionFailed,
                'failed to add widget within new section',
              )
                // @ts-expect-error
                .withPageRef(null, false)
                .withUserComponentId(
                  widgetDefinition.base.id as string,
                  'unified-widget',
                )
                .withParentError(error),
            ),
          );
      } else {
        resolve();
      }
    });
  }

  return { addPage, addWidget };
}
