import { PlatformContext } from '../../../../types/platformApi';
import {
  AddBlocksWidgetOptions,
  AppData,
} from '../../../../types/unifiedComponents';
import {
  AddUnifiedComponentsErrorCode as ErrorCode,
  createAddUnifiedComponentsError,
} from '../errors';
import { CompRef } from '@wix/document-services-types';
import { getAppDescriptorByAppData } from '@wix/blocks-widget-services/editor';

const TOKEN = 'editor-platform-host-integration';
const appDescriptors: Record<
  string,
  Awaited<ReturnType<typeof getAppDescriptorByAppData>>
> = {};

async function getDefaultPresetAndSize(appData: AppData, widgetId: string) {
  if (!appDescriptors[appData.appDefinitionId]) {
    appDescriptors[appData.appDefinitionId] = await getAppDescriptorByAppData(
      appData,
    );
  }
  const appDescriptor = appDescriptors[appData.appDefinitionId];
  const widgetDescriptor = Object.values(appDescriptor.widgets).find(
    ({ devCenterWidgetId }) => devCenterWidgetId === widgetId,
  );
  if (!widgetDescriptor) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBlocksWidgetFailed,
      'Could not fetch widget from app descriptor',
    ).withUserComponentId(widgetId, 'unified-widget');
  }

  const defaultPreset = widgetDescriptor.presets?.[0];
  const presetId = defaultPreset?.presetId;
  if (!presetId) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBlocksWidgetFailed,
      'Could not fetch default preset for blocks widget',
    ).withUserComponentId(widgetId, 'unified-widget');
  }

  const size = widgetDescriptor.defaultSize ?? defaultPreset.defaultSize;
  if (!size) {
    throw createAddUnifiedComponentsError(
      ErrorCode.addingBlocksWidgetFailed,
      'Could not fetch default size for blocks widget',
    ).withUserComponentId(widgetId, 'unified-widget');
  }

  return {
    size,
    presetId,
    mobilePreset: {
      id: presetId,
      size,
    },
  };
}

export async function addBlocksClosedWidget(
  context: PlatformContext,
  appData: AppData,
  {
    widgetId,
    containerRef,
    mobilePresetId,
    isStretched,
    presetId,
    size,
    position = { x: 0, y: 0 },
  }: AddBlocksWidgetOptions,
): Promise<CompRef> {
  let mobile = {};
  if (!presetId || !size) {
    const defaultValues = await getDefaultPresetAndSize(appData, widgetId);
    presetId = presetId ?? defaultValues.presetId;
    size = { ...size, ...defaultValues.size } as AddBlocksWidgetOptions['size'];
    mobilePresetId = presetId;
    mobile = defaultValues.mobilePreset;
  }

  const mobilePreset = {
    id: mobilePresetId,
    size,
  };
  const mobileScopedPreset = mobilePreset && {
    layout: mobilePreset.id,
    style: mobilePreset.id,
  };

  return new Promise<CompRef>((resolve, reject) =>
    // @ts-expect-error
    context.platformApiMethods.document.application.appStudioWidgets
      .addWidget(appData, TOKEN, {
        widgetId,
        installationType: 'closed',
        presetIds: presetId
          ? {
              layout: presetId,
              style: presetId,
            }
          : undefined,
        layout: {
          ...size,
          ...position,
          ...(isStretched
            ? {
                docked: {
                  left: { px: 0, vw: 0 },
                  right: { px: 0, vw: 0 },
                },
              }
            : {}),
        },
        layouts: {
          componentLayout: isStretched
            ? {
                type: 'ComponentLayout',
                height: size?.height,
                width: { type: 'percentage', value: 100 },
              }
            : {
                type: 'ComponentLayout',
                width: size?.width,
                height: { type: 'auto' },
                minWidth: size?.width,
                minHeight: size?.height,
              },
          containerLayout: {
            type: 'GridContainerLayout',
            rows: [{ type: 'fr', value: 1 }],
            columns: [{ type: 'fr', value: 1 }],
          },
          itemLayout: {
            id: '',
            type: 'GridItemLayout',
            gridArea: {
              columnStart: 1,
              columnEnd: 2,
              rowStart: 1,
              rowEnd: 2,
            },
            alignSelf: 'start',
            justifySelf: 'start',
            margins: {
              left: { type: 'percentage', value: 0 },
              top: { type: 'px', value: 0 },
            },
          },
        },
        containerRef,
        scopedPresets: {
          ...(mobileScopedPreset ? { mobile: mobileScopedPreset } : {}),
        },
        dimensionsByDevice: {
          desktop: size,
          ...(mobilePreset
            ? {
                mobile: mobilePreset.size,
              }
            : mobile),
        },
      })
      .then(resolve)
      .catch((error: Error) =>
        reject(
          createAddUnifiedComponentsError(
            ErrorCode.addingBlocksWidgetFailed,
            'Could not add blocks widget',
          )
            .withUserComponentId(widgetId, 'unified-widget')
            .withParentError(error),
        ),
      ),
  );
}
