import { DocumentServicesObject } from '@wix/document-services-types';
import {
  AppData,
  ComponentRef,
  WidgetViewState,
} from '@wix/editor-platform-sdk-types';
import { platformEvents } from '../../platformEvents';
import { reportBi } from '../../essentials';

declare global {
  // eslint-disable-next-line no-var
  var viewStatesRegistry: Record<string, string>;
}

let registry = (() => {
  // TODO: get rid of globalThis once there is single instance of host-integration
  // https://jira.wixpress.com/browse/EP-4008
  if (!globalThis.viewStatesRegistry) {
    globalThis.viewStatesRegistry = {};
  }

  return globalThis.viewStatesRegistry;
})();

const WIDGET_VIEW_STATE_CHANGED_EVENT_ID = 101;

function sendWidgetViewStateChangedBiEvent(props: {
  appDefId: string;
  hostAppId: number;
  componentId: string;
  // parentComponentId: string;
  // parentComponentType: string;
  modes: string[];
  rootWidget: boolean;
}) {
  reportBi(WIDGET_VIEW_STATE_CHANGED_EVENT_ID, {
    app_def_id: props.appDefId,
    host_app_id: props.hostAppId,
    component_id: props.componentId,
    // parent_component_id: props.parentComponentId,
    // parent_component_type: props.parentComponentType,
    modes: props.modes,
    root_widget: props.rootWidget,
  });
}

function getAppDefinitionId(data: any) {
  return data?.appDefinitionId ?? data?.applicationId;
}

function getApplicationIdByComponentRef(
  documentServices: DocumentServicesObject,
  componentRef: ComponentRef,
): number {
  const componentData = documentServices.components.data.get(componentRef);

  /**
   * NOTE: not sure this condition is 100% correct...
   * applicationId - number
   * appDefinitionId - hash
   * in some cases (idk exactly) – applicationId contains appDefintionId instead
   * might be related to the ref components...
   */
  if (componentData?.applicationId && componentData?.appDefinitionId) {
    return componentData.applicationId;
  }

  const appData = documentServices.platform.getAppDataByAppDefId(
    getAppDefinitionId(componentData),
  );

  if (!appData?.applicationId) {
    throw new Error(
      `App data for appDefinitionId ${componentData.appDefinitionId} is missing applicationId`,
    );
  }

  return appData.applicationId;
}

function assertComponentBelongsToApplication(
  documentServices: DocumentServicesObject,
  componentRef: ComponentRef,
  applicationId: number,
): void {
  const targetAppId = getApplicationIdByComponentRef(
    documentServices,
    componentRef,
  );
  // in some cases, applicationId is string...
  if (parseInt(targetAppId as any, 10) !== parseInt(applicationId as any, 10)) {
    throw new Error(
      `Component ${componentRef.id} does not belong to the application ${applicationId}. Cannot act on view mode for a component of another application.`,
    );
  }
}

function getInitialState(viewStates: Record<string, WidgetViewState>) {
  if (!Object.keys(viewStates).length) {
    return undefined;
  }

  const initialState = Object.entries(viewStates).find(
    ([_stateId, viewState]) => {
      return viewState.initial;
    },
  );

  const [id, viewState] = initialState
    ? initialState
    : Object.entries(viewStates)[0];

  return {
    id,
    viewState,
  };
}

export function getViewState(
  documentServices: DocumentServicesObject,
  appData: AppData,
  componentRef: ComponentRef,
) {
  if (!appData.applicationId) {
    throw new Error(`applicationId is required`);
  }
  assertComponentBelongsToApplication(
    documentServices,
    componentRef,
    appData.applicationId,
  );

  if (registry[componentRef.id]) {
    return registry[componentRef.id];
  }

  return getInitialState(getViewStatesByCompRef(documentServices, componentRef))
    ?.id;
}

export function setViewState(
  documentServices: DocumentServicesObject,
  appData: AppData,
  componentRef: ComponentRef,
  stateName: string,
): void {
  if (!appData.applicationId) {
    throw new Error(`applicationId is required`);
  }

  assertComponentBelongsToApplication(
    documentServices,
    componentRef,
    appData.applicationId,
  );

  const viewStates = getViewStatesByCompRef(documentServices, componentRef);

  if (!viewStates[stateName]) {
    return;
  }

  registry[componentRef.id] = stateName;

  sendWidgetViewStateChangedBiEvent({
    appDefId: appData.appDefinitionId!,
    hostAppId: appData.applicationId!,
    componentId: componentRef.id,
    modes: Object.keys(viewStates),
    rootWidget: false,
  });

  /**
   * TODO: DM types should be updated – https://github.com/wix-private/document-management/blob/master/document-services-types/src/platformObject.ts#L213
   */
  (documentServices.platform as any).livePreview.updateWidgetViewState({
    compId: componentRef.id,
    widgetViewState: { stateName, stateProps: {} },
  });

  documentServices.platform.notifyApplication(
    appData.applicationId,
    platformEvents.factory.viewStateChanged({ stateName, stateProps: {} }),
  );

  // Apply view state for nested widgets
  const nestedWidgets = getNestedWidgets(
    documentServices,
    appData,
    componentRef,
  );

  nestedWidgets.forEach((compRef) => {
    const childViewStates = getViewStatesByCompRef(documentServices, compRef);

    const childStateName = childViewStates[stateName]
      ? stateName
      : getInitialState(childViewStates)?.id;

    if (childStateName) {
      setViewState(documentServices, appData, compRef, childStateName);
    }
  });
}

function getNestedWidgets(
  documentServices: DocumentServicesObject,
  appData: AppData,
  componentRef: ComponentRef,
) {
  const nestedWidgets = documentServices.components
    // @ts-expect-error for some reason types for options are missed
    .getChildren(componentRef, { fromDocument: true, recursive: true })
    .map((childCompRef) => ({
      compRef: childCompRef,
      compData: documentServices.components.data.get(childCompRef),
    }))
    .filter(({ compData }) => !!compData)
    .filter(
      ({ compData }) =>
        !!compData.appDefinitionId &&
        compData.appDefinitionId === appData.appDefinitionId,
    );

  return nestedWidgets
    .map(({ compRef }) => {
      const isRef =
        documentServices.components.refComponents.isReferredComponent(compRef);

      if (isRef) {
        const children =
          documentServices.deprecatedOldBadPerformanceApis.components.getChildren(
            compRef,
          );

        return children?.[0];
      } else {
        return compRef;
      }
    })
    .filter((compRef) => !!compRef);
}

function getViewStatesByCompRef(
  documentServices: DocumentServicesObject,
  compRef: ComponentRef,
) {
  const stageData = documentServices.platform.controllers.getStageData(compRef);

  return stageData?.viewStates ?? {};
}

export function reset() {
  globalThis.viewStatesRegistry = {};
  registry = globalThis.viewStatesRegistry;
}

const VIEW_STATE_RESET_TRIGGERS = [
  // navigate between pages
  'pages.navigateTo',
  // Site Preview
  'documentMode.setComponentViewMode',
  // switch between DESKTOP and MOBILE
  'viewMode.set',
];

export const init = (documentServicesAPI: DocumentServicesObject) => {
  documentServicesAPI.registerToSiteChanged((updates?: string[]) => {
    if (
      updates &&
      updates.length &&
      updates.some((update) => VIEW_STATE_RESET_TRIGGERS.includes(update))
    ) {
      reset();
    }
  });
};
