import { DocumentServicesObject } from '@wix/document-services-types';
import {
  InstallOptions,
  InstallCallbacks,
} from '@wix/editor-platform-host-integration-apis';
import { createDependenciesDriver } from './appDependenciesDriver';

export interface InstallAppWithInstallerBuilderOptions {
  origin: {
    type: string;
    initiator: string;
    info: {
      type: string;
    };
  };
  appVersion?: string;
  callback: (data: any) => void;
}

const installApps = (
  documentServices: DocumentServicesObject,
  appDefinitionIds: string[],
  installOptions?: InstallOptions,
  callbacks?: InstallCallbacks,
) => {
  const { appsOptions, ...installationOptions } = installOptions || {};
  return documentServices.platform.addApps(appDefinitionIds, {
    ...appsOptions,
    ...installationOptions,
    ...callbacks,
  });
};

export { createDependenciesDriver, installApps };
