import { overrideImplementation } from '@wix/editor-platform-sdk-implementation';
import { init, getWorkerManager } from './bootstrap/init';
import {
  installApps,
  createDependenciesDriver,
} from './services/installServiceEditor';
import {
  silentInstall,
  isSilentInstallRunning,
} from './services/silentInstallService';
import { getComponentApp } from './services/componentService';
import {
  setEditorAPI,
  onSetAppPublicAPI,
} from './bootstrap/platformPostMessageService';
import { fetchAppsNames } from './utils/appMarketService';
import * as canvasOverlayService from './services/canvasOverlayService';
import * as pageReplaceService from './services/pageReplaceService';
import * as toolsEntryService from './services/toolsEntryService';
import * as viewStateService from './services/viewStateService';
import * as slotsService from './services/slotsService';

const API = {
  init,
  setEditorAPI,
  applications: {
    setAppPublicAPI: onSetAppPublicAPI,
    createDependenciesDriver,
    install: installApps,
    fetchNames: fetchAppsNames,
    silentInstall,
    isSilentInstallRunning,
  },
  getWorkerManager,
  components: {
    getComponentApp,
  },
  sdk: {
    overrideImplementation,
  },
  pageReplace: {
    ...pageReplaceService,
  },
  canvasOverlay: {
    hideAll: () => {
      canvasOverlayService.overlayStore.hideAll();
    },
    unhideAll: () => {
      canvasOverlayService.overlayStore.unhideAll();
    },
  },
  viewState: viewStateService,
  toolsEntry: toolsEntryService,
  slots: slotsService,
};

export default API;
