import { DocumentServicesObject } from '@wix/document-services-types';
import { AppData } from '@wix/editor-platform-sdk-types';
import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import { overlayStore } from '../../services/canvasOverlayService';

export const canvasOverlayEnhancer: PlatformApiEnhancerFunction = (
  _platformApiMethods,
  { documentServices },
) => {
  const { add, remove } = createCanvasOverlayMethods(documentServices);

  return {
    editor: {
      canvasOverlay: {
        add,
        remove,
      },
    },
  };
};

function createCanvasOverlayMethods(documentServices: DocumentServicesObject) {
  const add = (appData: AppData, url: string): void => {
    const { appDefinitionId, applicationId } = appData;
    if (!appDefinitionId || !applicationId) {
      throw new Error(`Unknown app: ${appData.appDefinitionId}`);
    }
    if (overlayStore.has(appDefinitionId)) {
      throw new Error(`App ${appDefinitionId} already have a canvas overlay`);
    }

    const urlParams = { url, applicationId, appDefinitionId };
    const builtUrl = new URL(
      documentServices.tpa.app.url.getSettingsModalUrl(urlParams, ''),
    );
    // because with instance yoshi wrappers fail
    builtUrl.searchParams.delete('instance');
    overlayStore.add({ appDefinitionId, url: builtUrl.href, visible: true });
  };

  const remove = (appData: AppData): void => {
    const { appDefinitionId } = appData;
    if (!appDefinitionId) {
      throw new Error(`Unknown app: ${appData.appDefinitionId}`);
    }
    if (!overlayStore.has(appDefinitionId)) {
      throw new Error(
        `App ${appDefinitionId} have no canvas overlay to remove`,
      );
    }
    overlayStore.remove(appDefinitionId);
  };

  return {
    add,
    remove,
  };
}
