import { PlatformApiEnhancerFunction } from '../../../types/platformApi';
import { getViewState, setViewState } from '../../services/viewStateService';

export const viewStateEnhancer: PlatformApiEnhancerFunction = (
  _platformApiMethods,
  { documentServices },
) => {
  return {
    editor: {
      widgets: {
        getViewState: getViewState.bind({}, documentServices),
        setViewState: setViewState.bind({}, documentServices),
      },
    },
  };
};
